import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { ErrorHandlerService } from '../../core/services/error-handler.service';
import { ScaConfirmationModuleConfigurationService } from '../../services';
import { NotificationService } from '../../ui/services/notification.service';

import * as fromActions from './actions';

@Injectable()
export class ScaConfirmationModuleConfigurationStoreEffects {
  constructor(
    private dataService: ScaConfirmationModuleConfigurationService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private notifications: NotificationService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ propertyId }) =>
        this.dataService.load(propertyId).pipe(
          map((response: IResponseSuccess) =>
            fromActions.loadSuccess({
              scaConfirmationModuleConfiguration: response.data[0],
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );

  update$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateRequest),
      switchMap(({ propertyId, scaConfirmationModuleConfiguration }) =>
        this.dataService
          .update(propertyId, scaConfirmationModuleConfiguration)
          .pipe(
            map((response: IResponseSuccess) => {
              this.notifications.updateSuccess(
                'sca_confirmation_module_configuration',
              );
              return fromActions.updateSuccess({
                scaConfirmationModuleConfiguration: response.data,
              });
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(fromActions.updateFailure(error));
            }),
          ),
      ),
    ),
  );
}
