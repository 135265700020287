import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from './actions';
import * as fromState from './state';

export const reducer = createReducer(
  fromState.initialState,
  on(fromActions.loadRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(
    fromActions.loadSuccess,
    (state, { scaConfirmationModuleConfiguration }) => ({
      ...state,
      data: {
        ...scaConfirmationModuleConfiguration,
        automatic_confirmation_requests: !!scaConfirmationModuleConfiguration.automatic_confirmation_requests,
        confirmations_notifications: !!scaConfirmationModuleConfiguration.confirmations_notifications,
      },
      isLoading: false,
      error: null,
    }),
  ),
  on(fromActions.loadFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.updateRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(
    fromActions.updateSuccess,
    (state, { scaConfirmationModuleConfiguration }) => ({
      ...state,
      data: scaConfirmationModuleConfiguration,
      isLoading: false,
      error: null,
    }),
  ),
  on(fromActions.updateFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.resetState, () => fromState.initialState),
);

export function scaConfirmationModuleConfigurationReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
