import { createAction, props } from '@ngrx/store';

import { ScaConfirmationModuleConfiguration } from '../../models';

export const loadRequest = createAction(
  '[Sca Confirmation Module Configuration] Load Request',
  props<{ propertyId: number }>(),
);

export const loadSuccess = createAction(
  '[Sca Confirmation Module Configuration] Load Success',
  props<{
    scaConfirmationModuleConfiguration: ScaConfirmationModuleConfiguration;
  }>(),
);

export const loadFailure = createAction(
  '[Sca Confirmation Module Configuration] Load Failure',
  props<{ error: any }>(),
);

export const updateRequest = createAction(
  '[Sca Confirmation Module Configuration] Update Request',
  props<{
    scaConfirmationModuleConfiguration: ScaConfirmationModuleConfiguration;
    propertyId: number;
  }>(),
);

export const updateSuccess = createAction(
  '[Sca Confirmation Module Configuration] Update Success',
  props<{
    scaConfirmationModuleConfiguration: ScaConfirmationModuleConfiguration;
  }>(),
);

export const updateFailure = createAction(
  '[Sca Confirmation Module Configuration] Update Failure',
  props<{ error: any }>(),
);

export const resetState = createAction(
  '[Sca Confirmation Module Configuration] Reset State',
);
